import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";

export const WorkshopContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: rgba(255, 255, 255, 1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopS", "desktop")`
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 1rem 1.25rem 0.5rem 1.25rem;
  `}

  ${media.down("tabletM")`
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
  `}
`;

export const WorkshopContentWrapper = styled(BentoItem)`
  grid-column: 1 / span 5;
  grid-row: 3 / span 4;
  height: fit-content;

  ${media.up("desktop")`
  `}

  ${media.between("laptopS", "desktop")`
  `}

  ${media.between("tabletM", "laptopS")`
  `}

  ${media.between("mobileL", "tabletM")`
  `}

  ${media.down("mobileL")`

  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;
