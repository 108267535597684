// components/About/Bio.jsx

import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints, heightBreakpoints } from "../../styles/breakpoints";
import Scrollbar from "../Scrollbar/Scrollbar";
import useStore from "../../stores/store";

const BioContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.bodyBgPrimary || "#f5f5f5"};
  border-radius: 10px;
  overflow: hidden;
  grid-column: 1 / -2;
  grid-row: 2 / 3;
  align-self: flex-start;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, height, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        grid-column: 1 / -1;
        height: ${Math.min(height * 0.8, 450)}px;
        margin: 1rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        grid-column: 1 / -1;
        height: ${Math.min(height * 0.8, 800)}px;
        margin: 1rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        grid-column: 1 / -1;
        height: ${Math.min(height * 0.8, 900)}px;
        margin: 1rem;
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        grid-column: 1/-1;
        margin: 0.5rem 1rem 1rem 1rem;
        height: ${Math.min(height * 0.8, 900)}px;
        ${
          isLandscape
            ? `
              width: calc(100% - 2rem);
              max-height: 90vh;
              min-height: 200px;
              overflow-y: auto;
            `
            : ""
        }
        ${
          isLandscape
            ? (() => {
                if (height <= heightBreakpoints.heightMIN) {
                  return `
                    max-height: 60vh;
                    min-height: 150px;
                  `;
                } else if (height <= heightBreakpoints.heightXXS) {
                  return `
                    max-height: 75vh;
                    min-height: 200px;
                  `;
                } else if (height <= heightBreakpoints.heightXS) {
                  return `
                    max-height: 80vh;
                    min-height: 250px;
                  `;
                } else if (height <= heightBreakpoints.heightS) {
                  return `
                    max-height: 75vh;
                    min-height: 300px;
                  `;
                } else if (height <= heightBreakpoints.heightM) {
                  return `
                    max-height: 70vh;
                    min-height: 350px;
                  `;
                } else if (height <= heightBreakpoints.heightL) {
                  return `
                    max-height: 65vh;
                    min-height: 400px;
                  `;
                }
                return "";
              })()
            : ""
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        grid-column: 1/-1;
        margin: 1rem;
        max-height: 80vh;
        ${
          isLandscape
            ? `
              width: calc(100% - 2rem);
              max-height: 90vh;
              min-height: 200px;
              overflow-y: auto;
            `
            : ""
        }
        ${
          isLandscape
            ? (() => {
                if (height <= heightBreakpoints.heightMIN) {
                  return `
                    max-height: 60vh;
                    min-height: 150px;
                  `;
                } else if (height <= heightBreakpoints.heightXXS) {
                  return `
                    max-height: 85vh;
                    min-height: 200px;
                  `;
                } else if (height <= heightBreakpoints.heightXS) {
                  return `
                    max-height: 80vh;
                    min-height: 250px;
                  `;
                } else if (height <= heightBreakpoints.heightS) {
                  return `
                    max-height: 75vh;
                    min-height: 300px;
                  `;
                } else if (height <= heightBreakpoints.heightM) {
                  return `
                    max-height: 70vh;
                    min-height: 350px;
                  `;
                } else if (height <= heightBreakpoints.heightL) {
                  return `
                    max-height: 65vh;
                    min-height: 400px;
                  `;
                }
                return "";
              })()
            : ""
        }
      `;
    } else if (width <= breakpoints.laptopM) {
      return `
        grid-column: 1/-1;
        margin: 1rem 1rem 1rem 2rem;
        height: ${Math.min(height * 0.8, 800)}px;
        ${
          isLandscape
            ? `
              width: calc(100% - 3rem);
              max-height: 90vh;
              min-height: 200px;
              overflow-y: auto;
            `
            : ""
        }
        ${
          isLandscape
            ? (() => {
                if (height <= heightBreakpoints.heightMIN) {
                  return `
                    max-height: 60vh;
                    min-height: 150px;
                  `;
                } else if (height <= heightBreakpoints.heightXXS) {
                  return `
                    max-height: 60vh;
                    min-height: 200px;
                  `;
                } else if (height <= heightBreakpoints.heightXS) {
                  return `
                    max-height: 65vh;
                    min-height: 250px;
                  `;
                } else if (height <= heightBreakpoints.heightS) {
                  return `
                    max-height: 70vh;
                    min-height: 300px;
                  `;
                } else if (height <= heightBreakpoints.heightM) {
                  return `
                    max-height: 70vh;
                    min-height: 350px;
                  `;
                } else if (height <= heightBreakpoints.heightL) {
                  return `
                    max-height: 75vh;
                    min-height: 400px;
                  `;
                }
                return "";
              })()
            : ""
        }
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        grid-column: 1/-1;
        margin: 1rem;
        height: ${Math.min(height * 0.8, 800)}px;
        ${
          isLandscape
            ? `
              width: calc(100% - 2rem);
              max-height: 90vh;
              min-height: 200px;
              overflow-y: auto;
            `
            : ""
        }
        ${
          isLandscape
            ? (() => {
                if (height <= heightBreakpoints.heightMIN) {
                  return `
                    max-height: 60vh;
                    min-height: 150px;
                  `;
                } else if (height <= heightBreakpoints.heightXXS) {
                  return `
                    max-height: 60vh;
                    min-height: 200px;
                  `;
                } else if (height <= heightBreakpoints.heightXS) {
                  return `
                    max-height: 65vh;
                    min-height: 250px;
                  `;
                } else if (height <= heightBreakpoints.heightS) {
                  return `
                    max-height: 70vh;
                    min-height: 300px;
                  `;
                } else if (height <= heightBreakpoints.heightM) {
                  return `
                    max-height: 70vh;
                    min-height: 350px;
                  `;
                } else if (height <= heightBreakpoints.heightL) {
                  return `
                    max-height: 65vh;
                    min-height: 400px;
                  `;
                }
                return "";
              })()
            : ""
        }
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        grid-column: 1/-1;
        grid-row: 2/-1;
        margin: 1rem 1rem 1rem 2rem ;
        height: ${Math.min(height * 0.8, 800)}px;
        ${
          isLandscape
            ? `
              width: calc(100% - 3rem);
              max-height: 90vh;
              min-height: 200px;
              overflow-y: auto;
            `
            : ""
        }
        ${
          isLandscape
            ? (() => {
                if (height <= heightBreakpoints.heightMIN) {
                  return `
                    max-height: 60vh;
                    min-height: 150px;
                  `;
                } else if (height <= heightBreakpoints.heightXXS) {
                  return `
                    max-height: 60vh;
                    min-height: 200px;
                  `;
                } else if (height <= heightBreakpoints.heightXS) {
                  return `
                    max-height: 65vh;
                    min-height: 250px;
                  `;
                } else if (height <= heightBreakpoints.heightS) {
                  return `
                    max-height: 70vh;
                    min-height: 300px;
                  `;
                } else if (height <= heightBreakpoints.heightM) {
                  return `
                    max-height: 70vh;
                    min-height: 350px;
                  `;
                } else if (height <= heightBreakpoints.heightL) {
                  return `
                    max-height: 65vh;
                    min-height: 400px;
                  `;
                } else if (height <= heightBreakpoints.heightXL) {
                  return `
                    max-height: 65vh;
                    min-height: 400px;
                  `;
                }
                return "";
              })()
            : ""
        }
      `;
    }
  }}
`;

const BioHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.textColorPrimary || "#333"};
  margin: 0;
  padding: 1rem;
  flex-shrink: 0;
  grid-row: 1;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: 0.9rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        font-size: 1.05rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: 1.125rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 0.75rem;
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${isLandscape ? `1.25rem` : `1.5rem`};
        padding: ${isLandscape ? `0.25rem 0.75rem` : `0.75rem`};
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: 1.25rem;
        padding: 0 0 0 1rem;
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        font-size: 2rem;
        padding: 0.5rem 0 0 1rem;
      `;
    } else {
      return `
        padding: 0 0 0 2rem;
      `;
    }
  }}
`;

const BioSubHead = styled.h2`
  color: ${({ theme }) => theme.textColorPrimary || "#333"};
`;

const BioContent = styled.div`
  flex-grow: 1;
  overflow: hidden;
  padding: 0 1rem;
  align-self: flex-start;
  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width } = $viewport;

    if (width <= breakpoints.tabletM) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    } else if (width <= breakpoints.laptopM) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    } else if (width <= breakpoints.laptopL) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        padding: 1rem 0 1rem 1rem;
      `;
    }
  }};
`;

const ScrollContent = styled.div`
  height: 100%;
  padding-right: 20px;
`;

const BioParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.textColorPrimary || "#444"};
  margin-bottom: 1rem;

  ${({ $viewport }) => {
    if (!$viewport) return "";
    const { width } = $viewport;

    if (width <= breakpoints.tabletM) {
      return `
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
      `;
    }
  }}
`;

const BioList = styled.div`
  & ul li {
    font-size: 1rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.textColorPrimary || "#444"};
    margin-bottom: 1rem;

    ${({ $viewport }) => {
      if (!$viewport) return "";
      const { width } = $viewport;

      if (width <= breakpoints.tabletM) {
        return `
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
      `;
      } else if (width <= breakpoints.tabletL) {
        return `
        font-size: 0.9rem;
        margin-bottom: 0.75rem;
      `;
      }
    }}
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.linkColor || "#0066cc"};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Bio = ({ viewport }) => {
  const [orientationKey, setOrientationKey] = useState(0);
  const [shouldShowScrollbar, setShouldShowScrollbar] = useState(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [isLandscape, setIsLandscape] = useState(viewport.isLandscape);
  const { theme } = useStore();

  const updateDimensions = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;

      // Add a small buffer (e.g., 1px) to account for potential rounding errors
      const shouldScroll = contentHeight > containerHeight + 1;
      setShouldShowScrollbar(shouldScroll);
    }
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationKey((prev) => prev + 1);
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const contentKey = useMemo(() => {
    return `bio-${viewport.width}-${viewport.height}-${isLandscape}`;
  }, [viewport.width, viewport.height, isLandscape]);

  useEffect(() => {
    updateDimensions();
  }, [viewport, isLandscape, updateDimensions]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });
    if (containerRef.current) resizeObserver.observe(containerRef.current);
    if (contentRef.current) resizeObserver.observe(contentRef.current);

    return () => resizeObserver.disconnect();
  }, [updateDimensions]);

  return (
    <>
      <BioHeader $viewport={viewport} theme={theme}>
        [ About Me ]
      </BioHeader>

      <BioContainer $viewport={viewport} theme={theme} ref={containerRef}>
        <BioContent $viewport={viewport} theme={theme}>
          <Scrollbar
            key={`${orientationKey}-${contentKey}`}
            theme={theme}
            scrollbarPosition="right"
            applyScrolling={true}
            forceScrollbar={true}
            trackMargin={{
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
            }}
          >
            <ScrollContent ref={contentRef} $viewport={viewport} theme={theme}>
              <BioSubHead $viewport={viewport} theme={theme}>
                Hey there, welcome!
              </BioSubHead>
              <BioParagraph $viewport={viewport} theme={theme}>
                If you're looking for my professional experience, head over to
                <StyledLink to="/experience">Experience</StyledLink> or
                <StyledLink to="/projects">Projects</StyledLink>. But, before
                you go, you should know...
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                There's a little more going on in this site than portfolios and
                a resumé...
              </BioParagraph>
              <BioSubHead>A Deliberate Pivot</BioSubHead>
              <BioParagraph $viewport={viewport} theme={theme}>
                Three years ago, I began a significant shift from a career in
                marketing, visual design, and web design into computer science,
                software development, and game development. That shift is still
                happening today.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                This site is my workbench, where I get to combine my new
                engineering skills, with my old and wizend creative skills to
                explore the intersection of storytelling, marketing, and
                emerging digital experiences.
              </BioParagraph>
              <BioSubHead>A Work in Progress</BioSubHead>
              <BioParagraph $viewport={viewport} theme={theme}>
                You might notice some areas look unfinished or placeholder-ish,
                like this page you're on now...
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Because it <em>is</em> a placeholder.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Right now, I'm working on finalizing the design framework
                powering the photo galleries and case studies. I'm in the middle
                of my "responsiveness" pass on those.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Once I have the layout working across devices, that bento box
                style will make its way here, and to the home page, etc.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Until then, let the abundant jank be part of the charm.
              </BioParagraph>
              <BioSubHead>Working with the Garage Door Open</BioSubHead>

              <BioParagraph $viewport={viewport} theme={theme}>
                For better or worse, I believe in transparency, letting you see
                the process as it unfolds. Right now, you're witnessing Phase 2
                of a three-phase project:
              </BioParagraph>
              <BioList $viewport={viewport} theme={theme}>
                <ul>
                  <li>
                    <strong>Phase 1:</strong> Recovering from a hack that
                    corrupted my dormant 20-year-old WordPress site, building a
                    new custom backend, and creating a temporary frontend as
                    triage.
                  </li>
                  <li>
                    <strong>Phase 2:</strong> (current) Launching the new
                    frontend framework, signaling the crisis is nearly over, and
                    I'm about ready to touch grass again.
                  </li>
                  <li>
                    <strong>Phase 3:</strong> Leveraging the 3D engine running
                    under the hood of this site to showcase case studies and
                    projects as immersive, interactive experiences.
                  </li>
                </ul>
              </BioList>
              <BioParagraph $viewport={viewport} theme={theme}>
                For the sake of development speed, there's no sense in making
                certain elements overly polished when I know I'm about to
                reimagine them in Phase 3. Right now, it's more important that
                the underlying system is tested and ready for what's coming.
              </BioParagraph>

              <BioSubHead>Explore and Enjoy</BioSubHead>
              <BioParagraph $viewport={viewport} theme={theme}>
                So, now you know what you're exploring: a living, active project
                where I test ideas, implement new skills, and occasionally break
                things.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                It's messy, imperfect, and always evolving - like life.
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Go ahead, poke around. And if something catches your eye or
                fires up your imagination,
                <StyledLink to="/contact">drop me a line.</StyledLink>
              </BioParagraph>
              <BioParagraph $viewport={viewport} theme={theme}>
                Enjoy!
              </BioParagraph>
            </ScrollContent>
          </Scrollbar>
        </BioContent>
      </BioContainer>
    </>
  );
};

export default Bio;
