import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { hexToRgb } from "../../../utils/hexToRgb";
import { ReactComponent as AvatarSVG } from "../../Avatar/avatarNoBubble.svg";

export const AboutAvatarContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 0;
  transition: all 0.3s ease;
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  display: grid;
  justify-content: center;
  height: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0);
    transform: translateY(-5px);
  }

  ${media.down("desktop")`
    // Styles for screens smaller than desktop
  `}

  ${media.between("laptopS", "desktop")`
    // Styles for screens between laptopS and desktop
  `}

  ${media.between("tabletS", "laptopS")`
    height: auto;
    min-height: 20rem;
  `}

  ${media.between("mobileL", "tabletS")`
    height: 100%;
    min-height: 15rem;
  `}

  ${media.down("mobileL")`
    height: auto;
  `}
`;

export const AboutAvatarContentWrapper = styled(BentoItem)`
  grid-column: 6 / span 4;
  grid-row: 1 / span 3;
  height: 100%;
  z-index: 0;

  h2 {
    position: relative;
    z-index: 10;
  }

  ${media.down("desktop")`
    // Styles for screens smaller than desktop
  `}

  ${media.between("laptopL", "desktop")`
    grid-column: 5 / span 5;
    grid-row: 1 / span 3;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 1 /span 6;
    grid-row: 1 / span 3;

    @media (orientation: landscape) {
      grid-column: 4 / span 4;
      grid-row: 1 / span 3;
    }
  `}

  ${media.between("tabletS", "laptopS")`
    grid-column: 4 /span 3;
    grid-row: 1 / span 3;

    @media (orientation: landscape) {
      grid-column: 1 / span 6;
      grid-row: 1 / span 3;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: 1;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: 1;
  `}
`;

export const StyledAvatar = styled(AvatarSVG)`
  // Root style for the largest display (desktop and above)
  transform: scale(2.5) translateY(-0.5rem) translateX(-1.5rem);
  width: clamp(12rem, 20rem, 22rem);
  height: clamp(12rem, 20rem, 22rem);
  margin: 0rem;

  .self-portrait__teeth {
    fill: ${({ theme }) => theme.hoverColorSecondary};
  }

  .self-portrait__facial-hair {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.iconColorSecondary)}, 0.8)`};
  }

  .self-portrait__sticker {
    fill: ${({ theme }) => theme.iconColorSticker};
  }

  .self-portrait--cls-5 {
    fill: ${({ theme }) => theme.hoverColorPrimary};
  }

  .self-portrait__shadow {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.iconColorSecondary)}, .5)`};
  }

  .self-portrait__stroke {
    fill: ${({ theme }) => theme.iconStrokeColor};
  }

  ${media.between("laptopL", "desktop")`
    transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
    width: clamp(10rem, 17.5rem, 20rem);
    height: clamp(15rem, 25rem, 25rem);
    margin: 0;

    @media (orientation: landscape) {
      transform: scale(1.5) translateY(-3.5rem) translateX(-1.5rem);
      width: clamp(10rem, 15rem, 20rem);
      height: clamp(10rem, 20rem, 25rem);
    }
  `}

  ${media.between("laptopM", "laptopL")`
    transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
    width: clamp(10rem, 17.5rem, 20rem);
    height: clamp(15rem, 25rem, 25rem);
    margin: 0;

    @media (orientation: landscape) {
      transform: scale(1.5) translateY(-3.5rem) translateX(-1.5rem);
      width: clamp(10rem, 15rem, 20rem);
      height: clamp(10rem, 20rem, 25rem);
    }
  `}

  ${media.between("tabletL", "laptopM")`
    transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
    width: clamp(10rem, 17.5rem, 20rem);
    height: clamp(15rem, 25rem, 25rem);
    margin: 0;

    @media (orientation: landscape) {
      transform: scale(1.5) translateY(-3.5rem) translateX(-1.5rem);
      width: clamp(10rem, 15rem, 20rem);
      height: clamp(10rem, 20rem, 25rem);
    }
  `}

  ${media.between("tabletM", "tabletL")`
    transform: scale(1.1) translateY(-10rem) translateX(-5.5rem);
    width: clamp(10rem, 17.5rem, 20rem);
    height: clamp(15rem, 25rem, 25rem);
    margin: 0;

    @media (orientation: landscape) {
      transform: scale(1.5) translateY(-3.5rem) translateX(-2rem);
      width: clamp(10rem, 15rem, 20rem);
      height: clamp(10rem, 19rem, 20rem);
    }
  `}

  ${media.between("tabletS", "tabletM")`
    transform: scale(1.5) translateY(-1.5rem) translateX(0.5rem);
    width: clamp(9rem, 15rem, 20rem);
    height: clamp(9rem, 15rem, 20rem);
    margin: 0;

    @media (orientation: landscape) {
      transform: scale(1.25) translateY(-3.5rem) translateX(-1.5rem);
      width: clamp(9rem, 12.5rem, 15rem);
      height: clamp(9rem, 17.5rem, 20rem);
      margin: 0;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    transform: scale(1.5) translateY(-1.65rem) translateX(-1.15rem);
    width: clamp(9rem, 12.5rem, 15rem);
    height: clamp(9rem, 15rem, 20rem);
    margin: -1.5rem;

    @media (orientation: landscape) {
      transform: scale(1.5) translateY(-1.65rem) translateX(-0.75rem);
      width: clamp(9rem, 10.5rem, 15rem);
      height: clamp(9rem, 11.5rem, 15rem);
    }
  `}

  ${media.down("mobileL")`
    transform: scale(1.5) translateY(-2.5rem) translateX(-1.75rem);
    width: clamp(9rem, 12.5rem, 15rem);
    height: clamp(9rem, 20rem, 25rem);
    margin: -1.5rem;
  `}
`;
