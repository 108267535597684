import styled from "styled-components";
import { media } from "../../../styles/breakpoints";
import { BentoItem } from "../../BentoBox/BentoBox";
import { typography } from "../../../styles/abstracts/typography";

export const CareerHighlightsContent = styled(BentoItem)`
  border-radius: 10px;
  padding: 1.5rem 2rem 1rem 2rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: ${({ theme }) => theme.secondaryColor};

  p,
  ul,
  li {
    color: ${({ theme }) => theme.textColorLight};
    font-size: ${typography.fontSizeSmall};
  }

  &:hover {
    background-color: ${({ theme }) => theme.tertiaryColor};
    transform: translateY(-5px);
  }

  ${media.up("desktop")`
    padding: 1.5rem 2rem 1rem 2rem;
  `}

  ${media.between("laptopL", "desktop")`
    padding: 0.5rem 1rem 0.25rem 1.5rem;

    ul, li, p {
      font-size: ${typography.fontSizeSmall};
    }
  `}

  ${media.between("laptopS", "laptopL")`
    padding: 0.5rem 1rem 0.25rem 1.5rem;

    ul, li, p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.between("tabletM", "laptopS")`
    padding: 0.5rem 1rem 0.25rem 1.5rem;

    ul, li, p {
      font-size: ${typography.fontSizeXs};
    }
  `}

  ${media.down("tabletM")`
    padding: 0.5rem 1rem 0.25rem 1.5rem;
    
    ul, li, p {
      font-size: ${typography.fontSizeXs};
    }
  `}
`;

export const CareerHighlightsContentWrapper = styled(BentoItem)`
  grid-column: 1 / span 4;
  grid-row: 4;
  height: fit-content;

  ${media.up("desktop")`
  `}

  ${media.between("laptopL", "desktop")`
  grid-column: 1 / span 5;
  grid-row: 4;
  `}

  ${media.between("laptopS", "laptopL")`
    grid-column: 1 / -3;
    grid-row: auto;

    @media (orientation: landscape) {
      grid-column: 1 / span 4;
    }
  `}

  ${media.between("tabletL", "laptopS")`
    grid-column: 1 / -2;
    grid-row: auto;

    @media (orientation: landscape) {
      grid-column: 1 / span 6;
    }
  `}

  ${media.between("tabletS", "tabletL")`
    grid-column: 1 / -2;
    grid-row: auto;

    @media (orientation: landscape) {
      grid-column: 1 / span 6;
    }
  `}

  ${media.between("mobileL", "tabletS")`
    grid-column: 1;
    grid-row: auto;
  `}

  ${media.down("mobileL")`
    grid-column: 1;
    grid-row: auto;
  `}

  h2 {
    position: relative;
    z-index: 10;
  }
`;
